import { Col, Container, Row } from "react-bootstrap";

import Image from "next/legacy/image";
import Link from "next/link";
import Script from "next/script";
import facebook from "/assets/generics/facebook.png";
import linkedin from "/assets/generics/linkedin.png";
import logo from "/assets/generics/logo.svg";

const Footer = () => {
  const setCookies = () => {
    try {
      window.openCookiesManager();
    } catch (error) {
      window.alert(
        "L'application Cherpas n'utilise pas de Cookies tiers sur votre appareil.",
      );
    }
  };
  return (
    <>
      <Row className="bg-orange3 py-4 d-flex w-100 m-0">
        <Col xs={8} md={12} lg={10} xl={8} className="mx-auto">
          <Row className="">
            <Col xs={12} md={3} className="mb-3">
              <div>
                <Link legacyBehavior prefetch={false} href="/" passHref>
                  <a className="text-decoration-none text-primary fw-bolder">
                    <Image
                      width={170}
                      height={"50px"}
                      src={logo}
                      alt="Logo Cherpas"
                    />
                  </a>
                </Link>
                <br />
                <p className="mt-2 lead fw-bolder text-primary">
                  Passez aux abonnements intelligents
                </p>
              </div>
            </Col>
            <Col md={3} className="mb-3">
              <div>
                <p className="fw-bolde h5 pb-1 text-primary">
                  <span className=" border-white border-bottom pe-2">
                    Produits
                  </span>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/comment-ca-marche"
                    passHref
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Principe
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link legacyBehavior prefetch={false} href="/equipe" passHref>
                    <a className="text-decoration-none text-primary fw-bolder">
                      Équipe
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/entreprises"
                    passHref
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Entreprises
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/actualites"
                    passHref
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Actualités
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/conseils"
                    passHref
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Conseils
                    </a>
                  </Link>
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} className="mb-3">
              <div>
                <p className="fw-bolder h5 pb-1 text-primary">
                  <span className=" border-white border-bottom pe-2">
                    Liens&nbsp;utiles
                  </span>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/confidentialite"
                    passHref
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Confidentialité
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link legacyBehavior prefetch={false} href="/cgu" passHref>
                    <a className="text-decoration-none text-primary fw-bolder">
                      CGU
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="https://aide.cherpas.com"
                    passHref
                  >
                    <a
                      className="text-decoration-none text-primary fw-bolder"
                      target="_blank"
                      rel="noopener"
                    >
                      FAQ
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/mentions-legales"
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      Mentions légales
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <span
                    className="text-primary fw-bolder"
                    role="button"
                    onClick={() => setCookies()}
                  >
                    Gestion&nbsp;des&nbsp;cookies
                  </span>
                </p>
              </div>
            </Col>
            <Col xs={12} md={3} className="mb-3">
              <div>
                <p className="h5 fw-bolder pb-1 text-primary">
                  <span className=" border-white border-bottom pe-2">
                    Contact
                  </span>
                </p>
                <div className="icons mb-1">
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="https://www.facebook.com/Cherpas/"
                    title="Page Facebook"
                    passHref
                  >
                    <a target="_blank" rel="noopener nofollow">
                      <Image
                        width={"40px"}
                        height={40}
                        src={facebook}
                        alt="Vers Facebook"
                      />
                    </a>
                  </Link>
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="https://www.linkedin.com/company/cherpas/"
                    title="Page LinkedIn"
                    passHref
                  >
                    <a target="_blank" rel="noopener nofollow">
                      <Image
                        src={linkedin}
                        width={"40px"}
                        height={40}
                        alt="Vers Linkedin"
                      />
                    </a>
                  </Link>
                </div>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="mailto:parler@cherpas.com"
                  >
                    <a className="text-decoration-none text-primary fw-bolder">
                      parler@cherpas.com
                    </a>
                  </Link>
                </p>
                <p className="mb-1">
                  &gt;&nbsp;
                  <Link legacyBehavior prefetch={false} href="/rejoindre">
                    <a className="text-decoration-none text-primary fw-bolder">
                      Nous&nbsp;rejoindre
                    </a>
                  </Link>
                </p>
                <div
                  className="justify-content-start"
                  id="d932ce2b-85dc-477c-aa8e-c438c46c9b2a"
                ></div>

                <Script
                  src="https://brand-widgets.rr.skeepers.io/generated/eea0f5e0-6f7c-b404-c978-4ccb6f23eec8/d932ce2b-85dc-477c-aa8e-c438c46c9b2a.js"
                  async
                  id="jkhgujjj"
                ></Script>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Container fluid className="py-4 d-flex bg-blue2 text-white">
        <Container>
          <Row>
            <Col className="small text-white text-center">
              Cherpas SAS est une société inscrite au registre du commerce et
              des sociétés de Rennes sous le numéro 853541233.
            </Col>{" "}
          </Row>{" "}
        </Container>
      </Container>
    </>
  );
};
export default Footer;
