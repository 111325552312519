import { Col, Nav, Navbar, Row } from "react-bootstrap";
import { faSpinner, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Clickable from "/components/generics/clickable";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import Link from "next/link";
import axios from "axios";
import { bool } from "prop-types";
import getToken from "../jannu/lib/getToken";
import logo from "/assets/generics/logo.svg";
import styles from "../../styles/module/kabru/NavBar.module.scss";
import { useRouter } from "next/dist/client/router";

const auth = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_URL,
});

const NavBar = () => {
  NavBar.propTypes = {
    noCTA: bool,
  };
  const needDisconnect = useSelector(
    (state) => state.jannuInfos.needDisconnect,
  );

  const router = useRouter();
  const dispatch = useDispatch();

  const [classNot1, setClass1] = useState("d-block d-lg-none");
  const [classNot2, setClass2] = useState("");

  useEffect(() => {
    annapurnaId && setClass1("notToggle d-block d-lg-none");
    annapurnaId && setClass2("not notIcon");
  }, [annapurnaId]);

  const [expanded, setExpanded] = useState(false);
  const [scrollState, setScrollState] = useState("navTop");
  const [loadingJannu, setLoadingJannu] = useState(false);

  const disconnectJannu = () => {
    const client_id = process.env.NEXT_PUBLIC_CLIENT_ID_AUTH0;
    getToken()
      .then((accessToken) => {
        var bodyFormData = new FormData();
        bodyFormData.append("token", accessToken);
        bodyFormData.append("client_id", client_id);
        auth
          .post("revoke_token/", bodyFormData, {
            "Content-Type": "multipart/form-data",
          })
          .then(() => {
            Cookies.remove("cherpasAccess");
            Cookies.remove("cherpasRefresh");
            setLoadingJannu(false);
          })
          .catch(() => {
            Cookies.remove("cherpasAccess");
            Cookies.remove("cherpasRefresh");
            setLoadingJannu(false);
          });
      })
      .catch(() => {
        Cookies.remove("cherpasAccess");
        Cookies.remove("cherpasRefresh");
        setLoadingJannu(false);
      });
  };

  useEffect(() => {
    if (needDisconnect) {
      dispatch({ type: "setDisconnectJannu", content: true });
      setLoadingJannu(true);
      disconnectJannu();
    }
  }, [needDisconnect]);

  useEffect(function setupListener() {
    saveScroll();
    window.addEventListener("scroll", saveScroll);

    return function cleanupListener() {
      window.removeEventListener("scroll", saveScroll);
    };
  });

  const saveScroll = () => {
    var scrolled = document.scrollingElement.scrollTop;
    if (scrolled >= 800) {
      if (scrollState !== "navAmir shadow") {
        setScrollState("navAmir shadow");
      }
    } else {
      if (scrollState !== "navTop") {
        setScrollState("navTop");
      }
    }
  };

  var annapurnaId = false;
  try {
    annapurnaId = Cookies.get("annapurnaIds").split("$")[0];
  } catch (error) {
    /* continue regardless of error */
  }

  const setJannu = () => {
    getToken()
      .then((accessToken) => {
        if (accessToken) {
          router.push("/mon-espace");
        } else {
          router.push("/connexion");
        }
      })
      .catch(() => {
        router.push("/connexion");
      });
  };
  return (
    <>
      <Row
        className={
          "sticky-top border-bottom mx-0 px-0 navBarKabru " + scrollState
        }
        style={{ zIndex: "10" }}
      >
        <Col xs={12} sm={12} md={12} lg={1} xl={1}></Col>
        <Col xs={12} sm={12} md={12} lg={10} xl={10} className="mx-0 px-2">
          <Navbar
            expand="lg"
            className="all navbar-kabru py-1 mx-auto px-2"
            sticky="top"
            expanded={expanded}
          >
            <Link
              legacyBehavior
              prefetch={false}
              href="/"
              className="d-flex"
              passHref
            >
              <a>
                <Clickable className="d-flex ms-0" force={3}>
                  <Image
                    width={"170px"}
                    height={"50px"}
                    src={logo}
                    alt="Logo Cherpas"
                    className="m-auto"
                  />
                </Clickable>
              </a>
            </Link>

            <div style={{ position: "relative" }}>
              <Navbar.Toggle
                className="menuNav"
                aria-controls="responsive-navbar-nav"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              />{" "}
              <span className={classNot1}></span>
            </div>

            <Navbar.Collapse id="responsive-navbar-nav" className="">
              <Nav className="ps-3 w-100">
                <Clickable
                  force={1}
                  className={`${styles.navLink} my-auto d-flex mx-0 justify-content-center`}
                >
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/electricite-gaz"
                    passHref
                  >
                    <a
                      className={styles.navLink}
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <p
                        role="button"
                        className={` mb-0 fw-bolder my-auto px-2 py-1 text-center text-primary`}
                      >
                        Électricité&nbsp;&&nbsp;Gaz
                      </p>
                    </a>
                  </Link>
                </Clickable>
                <Clickable
                  force={1}
                  className={` ${styles.navLink} my-auto d-flex mx-0 justify-content-center`}
                >
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/internet"
                    passHref
                  >
                    <a
                      className={styles.navLink}
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <p
                        role="button"
                        className="mb-0 fw-bolder my-auto px-2 py-1 text-center text-primary"
                      >
                        Internet
                      </p>
                    </a>
                  </Link>
                </Clickable>
                <Clickable
                  force={1}
                  className={` ${styles.navLink} my-auto d-flex mx-0 justify-content-center`}
                >
                  <Link legacyBehavior prefetch={false} href="/mobile" passHref>
                    <a
                      className={styles.navLink}
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <p
                        role="button"
                        className="mb-0 fw-bolder my-auto px-2 py-1 text-center text-primary"
                      >
                        Mobile
                      </p>
                    </a>
                  </Link>
                </Clickable>
              </Nav>
              <Nav className="me-1">
                <Clickable
                  force={1}
                  className={` ${styles.navLink} my-auto d-flex mx-0 justify-content-center`}
                >
                  <Link
                    legacyBehavior
                    prefetch={false}
                    href="/comment-ca-marche"
                    passHref
                  >
                    <a
                      className={styles.navLink}
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <p
                        role="button"
                        className="fw-bolder my-auto px-2 py-1 text-center text-primary"
                      >
                        Le&nbsp;principe
                      </p>
                    </a>
                  </Link>
                </Clickable>
                <Clickable
                  force={1}
                  className={` ${styles.navLink} my-auto d-flex mx-0 justify-content-center`}
                >
                  <Link legacyBehavior prefetch={false} href="/equipe" passHref>
                    <a
                      className={styles.navLink}
                      onClick={() => {
                        setExpanded(false);
                      }}
                    >
                      <p
                        role="button"
                        className="fw-bolder my-auto px-2 py-1 text-center text-primary"
                      >
                        L&rsquo;équipe
                      </p>
                    </a>
                  </Link>
                </Clickable>
                <Clickable
                  force={1}
                  className={` ${styles.navLink} my-auto d-flex mx-0 justify-content-center d-block d-lg-none`}
                >
                  <p
                    role="button"
                    onClick={() => {
                      setExpanded(false);
                      setJannu();
                    }}
                    className={`${styles.navLink} fw-bolder my-auto px-2 py-1 text-center text-primary`}
                  >
                    Mon&nbsp;espace
                  </p>
                </Clickable>
                <div className="d-none d-lg-block h-100 my-auto mx-1">
                  <p className="mb-0 p-0 mayNotified lead">
                    <FontAwesomeIcon
                      onClick={() => setJannu()}
                      role="button"
                      size="2x"
                      className="m-auto text-primary"
                      icon={loadingJannu ? faSpinner : faUserCircle}
                      spin={loadingJannu}
                    >
                      Connexion
                    </FontAwesomeIcon>
                    <span className={classNot2}> </span>
                  </p>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
        <Col xs={12} sm={12} md={12} lg={1} xl={1}></Col>
      </Row>
    </>
  );
};

export default NavBar;
