import { element, func, number } from "prop-types";

import React from "react";
import { motion } from "framer-motion";

const Clickable = React.forwardRef(
  ({ children, clic, force, ...props }, ref) => {
    Clickable.propTypes = {
      clic: func,
      force: number,
      children: element,
    };
    Clickable.displayName = "Clickable";

    switch (force) {
      case 0:
        return (
          <motion.div
            ref={ref}
            whileHover={{ scale: 1 }}
            whileTap={{ scale: 1 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.92 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.85 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      case 5:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
      default:
        return (
          <motion.div
            ref={ref}
            role="button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onTap={clic}
            {...props}
          >
            {children}
          </motion.div>
        );
    }
  },
);

export default Clickable;
